.title {
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 16px;
}

object {
  width: 100%;
}

.pdf {
  overflow: auto;
  height: 408px;
  box-shadow: 0 0 1px;
  border-radius: 14px;
  margin-bottom: 16px;
}

.emailForm {
  margin-bottom: 40px;

  label {
    display: inline-flex;
    margin-bottom: 6px;
  }

  div {
    position: relative;
  }

  span {
    position: absolute;
    left: 15px;
    top: 50%;
    margin-top: -12px;
  }

  input {
    box-sizing: border-box;
    width: 100%;
    background: #fff;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    border: 1px solid #cfdae4;
    border-radius: 12px;
    padding: 10px 11px 10px 42px;
  }
}

.buttons {
  text-align: center;
}

[dir='rtl'] {
  .emailForm {
    span {
      left: auto;
      right: 15px;
    }

    input {
      padding: 10px 42px 10px 11px;
    }
  }
}
